import React from 'react'

import JobList from '../../components/JobList'

export default class JobIndexPage extends React.Component {
  render() {
    return (
      <JobList/>
    )
  }
}