import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import Layout from './Layout'

class JobList extends React.Component {

  render() {
    const { data } = this.props
    const {
      data: { footerData, navbarData },
    } = this.props;
    const { edges: jobs } = data.allMarkdownRemark
    
    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <div className="section">
          <div className="content-wrapper flex-column justc-start alitems-center">
            <div className="job-hero">
              <h1 className="title-2 bold-weight text-medium mgb-xl m-mgb-m s-mgb-m">Carrières</h1>
            </div>
            <div className="job-list-container flex-column justc-center alitems-stretch">
              {jobs && (jobs
                .map(({ node: job }) => (
                  <Link to={job.fields.slug}
                        className="job-list-item " 
                        key={job.id}>
                    <h2 className="title-5 bold-weight text-light">
                      {job.frontmatter.title}
                    </h2>
                    <div className="job-subtitle flex-row justc-start alitems-start mgt-xs mgb-s">
                      <div className="job-category mgr-m">
                        <img className="job-icon" src="/img/icons/tag-icon.svg" alt='tag-icon' />
                        {job.frontmatter.category}
                      </div>
                      <div className="job-location">
                        <img className="job-icon" src="/img/icons/location-icon.svg" alt='tag-icon' />
                        {job.frontmatter.location}
                      </div>
                    </div>
                    <div className="job-tags">
                      {job.frontmatter.tags.map((tag, index) => (
                        <span key={index} className="job-tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </Link>
                )
              ))}
              <div className="flex-row justc-center mgt-l">
                  <Link to="/equipe-it"
                        className="cta fill blue small">
                    L'équipe I.T.
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

JobList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default function jobListData() {
  return (
    <StaticQuery
      query={graphql`
      query JobListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "job-page" } }}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                location
                category
                tags
                date
                description
              }
            }
          }
        }
        ...LayoutFragment
      }
      `}
      render={(data, count) => (
        <JobList data={data} count={count} />
      )}
    />   
  )
}